import {ClickableIcons} from "./Icons";
import React from "react";

const Home = () => {
    return (
        <div className="bg-black h-full">
            <div className="bg-black">
                <div className="text-white flex justify-center items-center h-screen select-none">
                    <div className="text-center justify-center mb-48">
                        <div className="w-full flex justify-center">
                            <img src="bitmoji.png" alt="" className="h-64" />
                        </div>
                        <h1 className={"text-3xl"}>Witaj w moim portfolio!</h1>
                        <ClickableIcons/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;