import { redirect } from "react-router-dom";
import Sidebar from "./Sidebar";

const Works = () => {
    const routeChange = () => {
        window.location.href = 'https://github.com/PatrykMolenda/hotel-api'
    }

    return (
        <div className="bg-black h-screen">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 text-white">
                <Sidebar redirect={"/"} />
                <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
                    <h2 className="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-white">Moje projekty</h2>
                    <p className="font-light text-white sm:text-xl">Sprwadź moje projekty!</p>
                </div>
                <div className="grid gap-10 m-8 lg:grid-cols-2">
                    <article className="p-6 rounded-lg border border-zinc-700 shadow-md bg-zinc-900 cursor-pointer" onClick={routeChange}>
                        <div className="flex justify-between items-center mb-5 text-white">
                            <span
                                className="bg-zinc-300 text-zinc-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded">
                                
                                PHP Symfony
                            </span>
                            <span className="text-sm">2024</span>
                        </div>
                        <h2 className="mb-2 text-2xl font-bold tracking-tight text-white"><a
                            href="#">Hotel Rest API</a></h2>
                        <p className="mb-5 font-light text-gray-400">Prosty system typu Rest API działający jako hotel służący do zarządzania wynajmowanymi pokojami. Projekt aktualnie został porzucony i nie będzie już w przyszłości aktualizowany</p>

                    </article>
                </div>
            </div>
        </div>
    );
}

export default Works;