import React from 'react';
import Sidebar from "./Sidebar";

const RawKey = () => {
    const [isShowingAlert, setShowingAlert] = React.useState(false);
    const formatKey = () => {
        return "-----BEGIN PGP PUBLIC KEY BLOCK-----\n" +
            "xsFNBGXH8C4BEADJYMA9K7Ta4nhmGYqAXyN4nDSH4UkOo/bpTbtDRL9ZGvOTF3H8\n" +
            "47osaZjws2UjN0MM8zo3lTuXSbHDlG0VI2ajLfcIER3fLftnR7i7JSvg36Jw5RDW\n" +
            "Bw0kWNs86wZo5l9FICwl/eqzVX/BGztiqC7etBKBCNDmuPFWlgT8Tab9gSeZGS00\n" +
            "6wwKX9ygf/x1OeJG6TGfAAwCAxWR4VBA7ovy35pvUuXQBDYtyAJeRQou4kaoOcQV\n" +
            "yJAVsIOrtLlpqGzUgoS2Ef4S9fQM3YL8QgFIP97IOu2TZuPZfKr5xJ0eyZWTzYto\n" +
            "OGC+pslMIeaxz+lEyLMLY1lKD/kLwXOUUTteKQXfQQZO3zwJteKmonCXPJD0RRJh\n" +
            "+/gd3vDWxEYIUt8u7/IxYJ2MLXj6HisbfApVOxcsf7iJvDWzsT5c7MXNpsn/8j7e\n" +
            "OgnV3OAAkgIK6x970VO7fwebXmnENiOHdtNz63n/SPffpPCrUgq01jOhgAK/79as\n" +
            "UkFmPCzyz3DaUNedEEus3CNl8aRvVob44FUpS25WtFcZgAgNeotWH8g2+/ZhBJ9P\n" +
            "rlWFDs2Omt5wClLFl49Ji0z3+Awkf3cvYFuuQNuMeTk5in8FarphH5cd7srqol67\n" +
            "FMrxQ8IbjO6S0h4Tz+x0XUuoaJhzo4oeNJnxLkPLWGbFFm7erEsA1GO16wARAQAB\n" +
            "zSFQYXRyeWsgPGZyYW5jdXpjb250YWN0QGdtYWlsLmNvbT7CwW0EEwEKABcFAmXH\n" +
            "8C4CGy8DCwkHAxUKCAIeAQIXgAAKCRAO+RsZwXsCpvUAEACDgJCXggjHIQgoxjzD\n" +
            "+C9S/s/LBrm+6m4l10wpEVW0P65QNL4OdelEK0muUSR4rNzxt4A36eRdFuK/xAaq\n" +
            "napjhw5R2T45vmq9L8gtw49NZs8tsffFNBwV3lZnOKnWPOMhqrvFLnEBTqPfmU5k\n" +
            "R2Ho7JMNc1I4JrMPXWhF7kDHgh1tlxpQSxv3TZzDCFsumhUmPBIBstKw1GNqRyXG\n" +
            "Bje1qEbAVKXphLfhDWgjMdaAjBCMQW2XWNpgqkveR87yLybT6hp2M7KhovYsOdUB\n" +
            "qo3YTRLAfo8KaiUTJ4yI34QzXjmkGlqKKEFYAg5dDbNVj8v7+vN4WJJ9kmxP5Kpu\n" +
            "TIK0gw7p1y/fOgBj3Rq/EoEzAbnGchKgaB3CfnhKXCTRm5w8l3PVieLbGAvQjS8A\n" +
            "RYxwuX1rwWNSRqeRP62PpYU3SlWQE0PUnPuJFa9jOn+lbZsEkQ2YuM4GohGjWuoE\n" +
            "ydmjMX1N3YYZJI7+1+S8CVnzkbrWKNHXHP62Hnav+ycTyDgwP3OsdaJi9+DWg2KH\n" +
            "5ybNDvF5RZLeSJdDveCw8yOutRsLrHn/G7ERL59TEcLkzsB1yTucFZFu3JS0bCsf\n" +
            "yIbyAllHrz7pJ6q++pIqX8w+HY5tR4N+3LFwxY2rttE3rn4a1z9tIGVyqZim7SO4\n" +
            "REtsaV5Bthr4PtaAD2ehW+/fxs7BTQRlx/AuARAAvxGvERIez3i9sRAufSNU58Jc\n" +
            "kUdl8AXwcsLXkrJEf4ItbmSRtHsMP4RNAJRXDJK+/b0Vc3GMDSU0lOP0eB6ruRIS\n" +
            "6PdjBMO0+EXJetS+bg2Y9OJRcCbmLEXi/CeJaeFSmwyek7qWZRTiaY8nPDXFgGqX\n" +
            "hj6P+HO5avn92RZml5jMUu+zexGgiJ8WptLbJw00YZnO8XF/IBAenjqSHa2zpSC+\n" +
            "k+1fzuMg2q71aW30rW7rYpP2eASHYbtO529INMRtPlMwhuuaLJdgDWlhDCk5DE6G\n" +
            "qi5SDhrtdKmbfP8m5LSqFPDclmPsOZXDyTgzT9aZBLVNF62Dd0tpvBOeaSoyyajR\n" +
            "s7WyTlo4n0X/qbxrnH13pXq0bNrjHzeL5GjC66p2B4PjYpMoLZBOby92t2fwYYbo\n" +
            "dR6tsyR820YWs2D9kTNdwEXl4GYlXTtVtltwnkuJ6bH/5buR4TH/67BCMRpf5o7y\n" +
            "IoTKX9qiVcrcKqZvt2jshM6CkfTFguooognefpJB2QnIbPAM9npHGUh2RwFPKL/J\n" +
            "aIVyADbh5pnrBKUT5Sc8WKnFILdKzKHuhYltZ4wdyCijlLkpQipPviC7mTWYdmqd\n" +
            "oHHMhFYlzUl5n2BWHdUGWh6EiGvpnkznB2R2xGI6/2raL8skdQpqCNelaSRdchSO\n" +
            "SbyfA5jmUWqCSBwCr1EAEQEAAcLDhAQYAQoADwUCZcfwLgUJDwmcAAIbLgIpCRAO\n" +
            "+RsZwXsCpsFdIAQZAQoABgUCZcfwLgAKCRDSdxnCpvOFw+LVD/4iDOAEb2AJR2aD\n" +
            "isf8wLkW4FRc3u6mAUxYaTnWLfBfgRIyR9lGha0lPk87nqN3nRsHzFlmb3kc7F1r\n" +
            "/InipmKDifMkKTgg9YgqD52qBc0MPE9kiz3wPMmnIa7U7goACynU5ELmvlCZJwPj\n" +
            "dmK/vBuB1RfvZyZuZt36BtWrqZAFrqkzx40NbMYQJk9QjjPSpiLjinAAVKLG2S4c\n" +
            "wg7nwBzCV1dzri2j01uRyBQwJuXSRqCpWiHYRkbaHigXYA2ODtEaMPSwSdIS7met\n" +
            "t2nfUS4AgYWs7FQbtSI/I0J6+hn38luKcQV0Um0MoMR2e3Wn6XFbLeePtqa00bym\n" +
            "udhXVJyrX+JCNvL2Ypf8EHiNevOeawJ2vdldSnNfuoInllKVWSEL2N1Mk8NGmDBT\n" +
            "WEdc3HcPMnjBADdfxmi+yZKvT4nU8RI8MiK0vd/le8n4cNVhhMLqeGClRo1FwMgW\n" +
            "S1XzTVFdup+ZINN+UvE2+rB3fVpd5auHRrMiiasTISB4R5nkN9080KmoFXKwZCBN\n" +
            "Nn+r81SkdoUifd499j0TMzXqgPxVcu76K+IxQo60LTga7/a2+MzxOcUYsBE1B4dE\n" +
            "p15OVwibkwDpwmecsGOnRb7xNIYF8guxG3zXmBvcGEAFG+cKJlg9DNvnEo6lDj9B\n" +
            "a5ukoFhg7GShsBrKG7F+g80PXhnXJvC7D/oDLDMLO1xTOWl2V6icjmboEBKiBHuN\n" +
            "+iM1QddI//prjgxa4ozRb+L/yY46zypu81NcxQpXkrAMsnNVbwgN1OyM25RsL9oW\n" +
            "yOtfv7983N4AvMXywg86f0bkV0dBpS/t88LSmq2l2kCVM9tj0W8VjAWi+c3wvqdS\n" +
            "XlKPb44+qRV+MxukSAzF8gSYQRuwHRuWwaQysiHeAn+CO+fvfi58fkLYD+3VdDXI\n" +
            "qJrNPYuzwS5Em28PSUBnn6FIArvaOrG//HguxmYb62IqCSuct98EQ9P4+kpVrKEV\n" +
            "EtXeSveHDbZBVsPKmCTaX5i2BebS1/dOaGwC/ZjbsBXQNCjwEm5wmoKNwaeWHaBu\n" +
            "rC5hd6PojMUVdNOc8ILx/CjadmtsUj0rcb4FKx9vTbBKfYqzPx7n6cBnrVsmkqWH\n" +
            "ichMSRsBUcoH7ozuDXLCaw58FzU3LhzZNv3+EKUo4OXZjm/0LU/djekF4orNV/4q\n" +
            "hPy+a6tOIw+CdSKVfPbpXFdYYl5tP6xIRVPnY9wUXffB26FzOpmdBK3o6S39ZEbT\n" +
            "7hBBbqMk/secPFS/07wzdFZ9wZSkOi2rWHe7dTdbYWe8BxwH4PaM5llB0Mwq2nzb\n" +
            "tyFJru+4OloPXPeHDBJQwA6j5bpY5lWpBGMCJ5BzdjpgiDmskmq6ynaMLz2mGzqM\n" +
            "XR9vVhf5nQUDMs7BTQRlx/AuARAAwL4qapTn6wDp9NfW90Qy8+pCSVNtIn9ASsYq\n" +
            "RkD/ECNufoJSkRqYN54xI8m78km5E/gmSDG4lGF9vFZ4wy5x9W7HEXk0UbjDAdHF\n" +
            "bQshQVcP3R3q/RwWv1ovyRakoaJiSEoepUWTk0qjNZMZhR3yyzqb6Yy/K5lep6oz\n" +
            "cmV36CYc5VYvQJoFXcK2GYeiF2i8QVJUB71jktkzBTrjTCrowsfNbVLVGBRxfPQZ\n" +
            "fMnkSdV3J0nPvXvNNK8SFKi8g3Q8kqfSL9XCs6eQQQ6A+e7H05yFsCWMJzvsR8o2\n" +
            "m8Sv/MyCCXIS0ElKYn29wBMuezXq9GUdzgni8TL9VF7DYiv4XsgDKYqFtGzg9ir6\n" +
            "WO4SUBtU+XX+lI5SRgknPHP/9m+4sWe32zASOTfbHhgINJSEAQ5Bm3aTqN+VvHhd\n" +
            "IXBicheuB+WVzQElJ94JYhKzt+/08dU2zor3/uv/3sbq9I8bDvImwFsXpKbMUww7\n" +
            "TOFWKkOizE51T0aY0BkoWJRrd42ZKrJby5CmH8JyZnImZhJ7qSuMw7B4FjQfhTfy\n" +
            "7kQyTSNf8HCVD6WGVNMKXsjs9Xoz64QgrnBKTWeTm8DlpuK1F/i059QrJFH5julM\n" +
            "B9xL9SY5yixgN+GKlHhwmcl3BfA1Pb2HvKI4SCR8CY/qqVP0Q35DtnizMOX96rSU\n" +
            "gTLd7j0AEQEAAcLDhAQYAQoADwUCZcfwLgUJDwmcAAIbLgIpCRAO+RsZwXsCpsFd\n" +
            "IAQZAQoABgUCZcfwLgAKCRD1j2K2y7lu7+NPD/43wLEIFbdKUKI14vRZgEWhcYiQ\n" +
            "0eHoaojY0PP9QD+DWIDX+JHL892LxrUZjkWx8zvxa4F3sUNI079FnjHITOFZXdcw\n" +
            "wy2vfysJBpEXxQyFTxhQy/yA676N1jDnCG5mz/HMFocEdZNo4h/42EUE+djfVUDV\n" +
            "gZ1rGqqB3LT6o8kQEDYp6K68ddSw8UZESHgX82DL5n9swROuHF1J+fWOHPh7kNFo\n" +
            "Cxj5J9UpOtl5t/ANfmQn/zHvLVgJU7I+5JDPV7VhasOtcw979YS+87w5QXQI1gLL\n" +
            "jiWTdfOzNK7l8tSMsydesboCkZ2UxL9ul3344gsz1J1mP6Ro8YykT8Qr5+shFaHz\n" +
            "f18nMyJ0azkibt2vflbsOn2d4UrOuzWLx1BCP7eY61UZh+ReXN8TkQram+IIuZ/D\n" +
            "hGMfrUKiVleSD7CSgwh18rvciSJiazh41dgcxwG8c63KcWWGSxCSY0VFg//KUJkT\n" +
            "Cpy6JmK4JNzhBCKk8b3Smtl/9WLwaXn/wE0VznnLQq6JZhn+3XBfP+t/Q4x71Ydz\n" +
            "GbRn3hffwElMB4aiw1zByl+RzLPg+fTn74eLYrOsXM5ZIl5E9bb3zDUZqwQmvg5I\n" +
            "T1syhIDL0q8gVBnsT9ceYLdkYNE5lozMJiyYrufe6D4TQOVUbCXxuiKFjFvtgpN/\n" +
            "W3EcopGWpbt6/tkEH7irEACadPQNlx0bD8lvkTNDEw03u3YipZIYLcrjxb2BZL72\n" +
            "YlPIQOm+PZjmW1KnwJAoKfprHBRw0u14wzNbBQa9Q7r58EhDQzb051lU5dcGn3ox\n" +
            "f7liAV6nBoNP8pAFEmoSPH1s6p/sQ+jYumTA2PaNnD1dc1tZM7dWebpYrZ5+lgV1\n" +
            "pTlj6qAFJA99EymEqFjLEPotl31EhK/D0qFPtdaC0CMD2Pj+0+Xx/tGKXnpjh1Zg\n" +
            "diJiNnI/ZTypa/Muq5FGYeUI5ETBJjo+ipvI8Xo24XKk0negWMXYShIc6GrOVuWp\n" +
            "d7EMG7gKe/MtfB9Cdn2X+AHNcu/MMSNWIfCiSgujal/h33LxZu1mebWGGRFcekW6\n" +
            "t7c4fhkJ/dpJr0iaVAdN0quR6NmyWmaPIFj/O63x4J56hGap7GNwvpwG915Idg5e\n" +
            "NIZ2u+a66oqCTUlwTvuB4bl9sUkFxdjjHXFGhplX6MgT8VoIJQDBSsiOFRSBXyJY\n" +
            "mtfTcdiExI9CNG4SK4761KkMTbgnlZlejaP+ZQMxXZd58pu3kzCzHKfrDbxKcjiV\n" +
            "MlxRP6L2kUGr8/MuBbKIV5h+U/8BLX0l8eqSj25O2aNsNFQAaBzTKueSE6rSssgj\n" +
            "UbPSHn5hsLp9cF3aUGY+UelQGEEdWtP9fGpTRQvGi0vgEdeDKuRy9+Ys5Fq7aTzb\n" +
            "fw==\n" +
            "=q4ph\n" +
            "-----END PGP PUBLIC KEY BLOCK-----";
    };
    function onClick()
    {
        setShowingAlert(true);
        navigator.clipboard.writeText(formatKey()).then((r) => {
            setTimeout(() => {
                setShowingAlert(false);
            }, 1000)
        });
    }
    return (
        <div className={"bg-black text-white"}>
            <Sidebar redirect={"/"} />
            <div className="fixed top-0 right-0 m-8">
                <div id="toast-success"
                     className={`flex items-center w-full max-w-xs p-4 mb-4 bg-gray-800 rounded-lg shadow text-gray-400 ${isShowingAlert ? 'alert-shown' : 'alert-hidden'}`}
                     onTransitionEnd={() => setShowingAlert(false)}
                     role="alert">
                    <div
                        className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg bg-green-800 text-green-200">
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                             fill="currentColor" viewBox="0 0 20 20">
                            <path
                                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                        </svg>
                        <span className="sr-only">Sprawdź ikone</span>
                    </div>
                    <div className="ms-3 text-sm font-normal">Skopiowano!</div>
                </div>
            </div>
            <div className="text-center">
                <a className={"font-bold cursor-pointer"} onClick={onClick}>Kopiuj klucz</a>
                <pre>
                    <code>
                        {formatKey()}
                    </code>
                </pre>
            </div>
        </div>
    );
}

export default RawKey;