import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./Home";
import Works from "./Works";
import Key from "./Key";
function App() {
  return (
      <>
          <Router>
              <Routes>
                  <Route path={"/"} element={<Home/>} />
                  <Route path={"/works"} element={<Works/>} />
                  <Route path={"/key"} element={<Key/>}/>
              </Routes>
          </Router>
      </>
  );
}

export default App;