import React from 'react';
import {Link} from "react-router-dom";

interface Props {
    redirect: string;
}

const Sidebar: React.FC<Props> = ({redirect}) => {
    return (
        <Link to={redirect}><div className={"fixed top-0 left-0 m-10 text-white"}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                 stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"/>
            </svg>
        </div></Link>
    );
}

export default Sidebar;